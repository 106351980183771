import {
  getFallbackOgImageUrl,
  getIsInsideAnIframe,
  getSiteUrl,
  imageUrlBuilder,
  splashSiteUrl,
  withoutUndefinedValues,
} from "@libry-content/common";
import { DecorativeImage, ImageWithMetadata } from "@libry-content/types";
import Head from "next/head";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useEffect, useReducer, useState } from "react";
import type { Thing } from "schema-dts";
import { useTranslation } from "../utils/hooks/useTranslation";
import { loggError } from "../utils/logging";
import { removeTrailingSlash } from "../utils/removeTrailingSlash";
import { withErrorBoundary } from "./errorPages/withErrorBoundary";
import { useCommonData } from "./layout/CommonDataProvider";
import { getSchemaOrgImageUrl } from "./library/utils";
import { getSchemaOrgSiteImage } from "./site/utils";

export interface SEOProps {
  title: string;
  description: string;
  canonicalPath: string;
  externalImageUrl?: string;
  sanityImage?: DecorativeImage | ImageWithMetadata;
  icon?: string;
  schemaOrgThing?: Omit<Thing, string> | Omit<Thing, string>[];
}

export function getTitle(title: string, siteName?: string): string {
  if (!siteName) return title;
  if (!title.length) return siteName;
  if (title === siteName) return title;
  return `${title} | ${siteName}`;
}

const addDefaults = (schemaOrgThing: Omit<Thing, string>, title: string, description: string, imageUrl: string) => ({
  "@context": "https://schema.org/",
  name: title,
  description,
  image: imageUrl,
  ...withoutUndefinedValues(schemaOrgThing),
});

const addThingDefaults = (
  thing: NonNullable<SEOProps["schemaOrgThing"]>,
  ...defaults: [string, string, string]
): NonNullable<SEOProps["schemaOrgThing"]> =>
  Array.isArray(thing) ? thing.map((item) => addDefaults(item, ...defaults)) : addDefaults(thing, ...defaults);

function SEO(props: SEOProps) {
  const { ts, lang } = useTranslation();
  const { site } = useCommonData();

  const canonicalPath = removeTrailingSlash(`/${lang}${props.canonicalPath}`);

  useReplaceBadUrl(canonicalPath);

  const siteImg = getSchemaOrgSiteImage(site);
  const iconUrl = siteImg ? getSchemaOrgImageUrl(siteImg) : undefined;

  const imageUrl = props.externalImageUrl ?? getImageUrl(props.sanityImage) ?? getFallbackOgImageUrl(site) ?? "";

  const schemaOrgData = props.schemaOrgThing
    ? addThingDefaults(props.schemaOrgThing, props.title, props.description, imageUrl)
    : undefined;

  useEffect(() => {
    if (!/^\//.test(canonicalPath)) {
      loggError(new Error("path må starte med /"));
    }
  }, [canonicalPath]);

  const canonicalUrl = `${site ? getSiteUrl(site, { skipLanguage: true }) : splashSiteUrl}${canonicalPath}`;

  const siteName = ts(site?.name);
  const title = getTitle(props.title, siteName);

  return (
    <Head>
      <title>{title}</title>
      <link rel="icon" type="image/png" href={props.icon ?? iconUrl} />
      <link rel="canonical" href={canonicalUrl} />
      <meta name="description" content={props.description} />
      <meta property="image" content={imageUrl} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:description" content={props.description} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:image" itemProp="image" content={imageUrl} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={props.description} />
      <meta name="twitter:image" content={imageUrl} />

      {schemaOrgData && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaOrgData, null, 2) }}
        />
      )}
    </Head>
  );
}

const seoImageheight = 630;
const seoImageWidth = 1200;

function getImageUrl(image: SEOProps["sanityImage"]): string | undefined {
  if (!image?.asset) return undefined;

  return imageUrlBuilder(image)
    ?.quality(85)
    .size(seoImageWidth, seoImageheight)
    .bg("eee") // Use a background that approximates the background gradient we have in SanityImage
    .format("jpg")
    .url();
}

type RedirectPaths = { expected: string; actual: string | null; params?: string };

const maxRedirects = 3;

/**
 * Plasserer denne hooken i SEO. Konseputelt føles det ut som det hører hjemme under SEO siden det handler om å ha korrekt url, og fordi vi her har tilgjengelig hvilken path som regnes "som riktig" (cannonical). I tillegg trenger vi da bare å løse dette ett sted (gitt at alle pages bruker SEO-komponenten), istedenfor feks rundt i alle getStaticProps for hver enkelt side
 * Mange url'er inneholder en human-readable del som kan endre seg. Oppdaterer derfor url'en hvis man kommer inn med en utdatert url, slik at den blir riktig hvis man feks deler lenken. Man kommer til riktig side likevel pga id'en.
 */
const useReplaceBadUrl = (canonicalPath: string) => {
  const router = useRouter();
  const pathname = usePathname();
  const urlParams = useSearchParams()?.toString();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [lastThreeRedirects, addRedirectAttempt] = useReducer(
    (state: RedirectPaths[], paths: RedirectPaths) => [...state, paths].slice(-maxRedirects),
    []
  );

  useEffect(() => {
    // If we are inside an iframe we assume we are in a studio-preview
    if (getIsInsideAnIframe()) return;
    if (isRedirecting) return;

    const expectedPath = canonicalPath;
    const pathIsCorrect = pathname === expectedPath;

    if (pathIsCorrect) return;

    const redirectedThreeTimes =
      lastThreeRedirects.length >= maxRedirects &&
      lastThreeRedirects.every(({ expected }) => expected === expectedPath);

    // Prevents accidential loop. Might be scenarios where we have a bug or for some reason are not able to fix the bad url for some reason. This should not loop/freeze the app.
    if (redirectedThreeTimes) {
      console.error(`Tried to redirect ${maxRedirects} times without success`, { expectedPath, pathname });
      return;
    }

    const newPath = `${expectedPath}${urlParams ? `?${urlParams}` : ""}`;

    setIsRedirecting(true);
    router.replace(newPath);
    addRedirectAttempt({ expected: expectedPath, actual: pathname, params: urlParams });
    console.log(
      `Url did not match expected path. Replacing url. Retry ${lastThreeRedirects.length + 1}/${maxRedirects}`,
      {
        path: pathname,
        expectedPath,
      }
    );

    setTimeout(() => setIsRedirecting(false), 1000); // Small dealy to allow pathname to update before trying again
  }, [router, canonicalPath, lastThreeRedirects, pathname, urlParams, isRedirecting]);
};

export default withErrorBoundary(SEO, "SEO");
