/* eslint-disable i18next/no-literal-string */
import { BiblioteksentralenLogoWithName } from "@biblioteksentralen/icons";
import { Box, Container, Flex, Heading, Link, Stack, Text, VStack } from "@biblioteksentralen/react";
import { filters, imageUrlBuilder } from "@libry-content/common";
import { Site, SplashPage as SplashPageType } from "@libry-content/types";
import { GetStaticPaths, GetStaticProps } from "next";
import { groq } from "next-sanity";
import { useLiveQuery } from "next-sanity/preview";
import { LinksToAllSites } from "../../components/LinksToAllSites";
import SEO from "../../components/SEO";
import SplashPageFooter from "../../components/splashPage/SplashPageFooter";
import { sanityClient } from "../../utils/sanity/client";
import { locales, validateLocaleOrFallbackToDefault } from "@libry-content/localization";
import { getMessages } from "../../i18n";

const query = groq`{
  "splashPage": *[_type == "splashPage"][0],
  "sites": *[_type == "site" && ${filters.isNotDraft} && isLive] {
    municipality,
    customDomain,
    subdomain,
    defaultLanguage,
    _id,
    name
  } 
}`;

type Props = {
  splashPage?: SplashPageType;
  sites?: Pick<Site, "municipality" | "customDomain" | "subdomain" | "_id" | "name" | "defaultLanguage">[];
};

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: locales.map((locale) => ({ params: { locale } })),
    fallback: "blocking",
  };
};

export const getStaticProps: GetStaticProps = async (ctx) => {
  const splashPage = await sanityClient.fetch<Props>(query);
  const locale = validateLocaleOrFallbackToDefault(ctx.params?.locale);

  return {
    props: { ...splashPage, messages: await getMessages(locale) },
    revalidate: 60,
  };
};

function SplashPage(props: Props) {
  const [data] = useLiveQuery(props, query);

  return (
    <>
      <SEO
        title="Libry Content"
        description={data.splashPage?.someDescription ?? "Nettsidebygger for bibliotekene i Norge"}
        sanityImage={data.splashPage?.someBilde}
        canonicalPath="/"
        icon="/favicon.png"
      />
      <Header {...props} />
      <Sites {...props} />
      <SplashPageFooter background="gray.100" />
    </>
  );
}

const Header = (props: Props) => (
  <Box
    as="section"
    minH={"110vh"}
    backgroundColor="blackAlpha.700"
    overflow="hidden"
    position="relative"
    isolation="isolate"
    color={"white"}
    background={`linear-gradient(rgba(0, 0, 0, 0.55),rgba(0, 0, 0, 0.65)), url("${imageUrlBuilder(
      props.splashPage?.image
    )
      ?.width(1200)
      .format("webp")
      .quality(80)
      .url()}")`}
    backgroundSize="cover"
    backgroundPosition="center" // FIXME: Background image that respects hotspot
  >
    <Flex
      flexDirection="column"
      justifyContent="center"
      position="relative"
      paddingY="max(10vmin, 3rem)"
      minH={"100vh"}
    >
      <Container maxW={`container.lg`} paddingX={{ base: "1rem", md: "2rem" }}>
        <VStack height="100%" paddingTop="5vh" textAlign="center">
          <Link href="https://www.bibsent.no/">
            <Box position="absolute" top="1rem" left="1rem" color="white" _hover={{ color: "#ddd" }}>
              <BiblioteksentralenLogoWithName aria-label="Biblioteksentralen" height="2.5em" />
            </Box>
          </Link>
          <Heading as="h1" fontSize={{ base: "4xl", md: "6xl" }} fontFamily="serif" lineHeight={1}>
            Libry Content
          </Heading>
          <Text fontSize={{ md: "lg" }}>Nettsider og formidlingsplattform skreddersydd for bibliotek</Text>
          <Link
            href="https://librycontent.no/"
            marginTop="1.5rem !important"
            variant="plain"
            backgroundColor="hsl(37deg 100% 72%)"
            _hover={{ backgroundColor: "hsl(37deg 100% 78%);" }}
            color="black"
            fontSize="xs"
            borderRadius="sm"
            padding=".75em 2em"
          >
            Les mer om Libry Content her
          </Link>
        </VStack>
      </Container>
    </Flex>
  </Box>
);

const Sites = (props: Props) => (
  <Box paddingY="6rem">
    <Container maxW="container.md">
      <Stack spacing="1rem" alignItems="flex-start">
        <Heading as="h2" size="md">
          Våre kunder
        </Heading>
        <Text>Besøk et av bibliotekene som bruker Libry Content i dag:</Text>
        <LinksToAllSites sites={props.sites} paddingTop="1rem" />
      </Stack>
    </Container>
  </Box>
);

export default SplashPage;
